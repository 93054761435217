import Image from "next/image";
import googlePlay from "/public/img/googleplay.svg";
import appStore from "/public/img/appstore.svg";
import qrcode from "/public/img/qrcode.png";
import Link from "next/link";

export default function StoreDownload() {
    return (
        <div id="qrCodeDiv" className="section padding-top-bottom-80 over-hide sep-background-1">
            <div className="sep-background-over-dark"></div>
            <div className="container z-bigger-10 position-relative">
                <div className="row justify-content-center">
                    <div className="col-sm-8 col-md-6 col-lg-7 pr-lg-4 pr-xl-5 align-self-center text-center text-md-left pb-4 pb-md-0"  data-scroll-reveal="enter bottom move 50px over 0.4s after 0.3s">
                        <h3 className="mb-3 color-yellow">이대로</h3>
                        <p className="mb-4 lead color-white weight-600">지금 진학을 계획하세요!</p>
                        <Link href={process.env.NEXT_PUBLIC_GOOGLE_PLAY_LINK} className="btn btn-white btn-radius parallax-elements js-tilt mt-2">
                            <Image src={googlePlay} alt="" />
                        </Link>
                        <Link href={process.env.NEXT_PUBLIC_APPLE_STORE_LINK} className="btn btn-white btn-radius parallax-elements js-tilt mx-3 mt-2">
                            <Image src={appStore} alt="" />
                        </Link>
                    </div>
                    <div className="col-sm-8 col-md-6 col-lg-5 img-wrap pl-lg-4 pl-xl-5 align-self-center"  data-scroll-reveal="enter bottom move 50px over 0.4s after 0.3s">
                        <div className="section border-4 p-3 p-sm-4 background-white">
                            <Image src={qrcode} alt="" />
                            <p className="mt-3 weight-600 text-center">QR코드를 스캔하면 앱을 바로 다운받을 수 있습니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
