import Layout from "../components/layouts/layout";
import Hero from "../components/home/hero";
import Company from "../components/company";
import Services from "../components/home/services";
import StoreDownload from "../components/store-download";
import News from "../components/home/news";
import axios from "axios";
import Announcement from "../components/announcement/announcement";

export default function Home({ newses }) {
	return (
		<Layout>
			<Hero />
			<Announcement />
			<Company />
			<Services />
			<StoreDownload />
			<News newses={newses} />
		</Layout>
	);
}

export async function getServerSideProps(context) {
	const res = await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/news/list?osType=WEB&size=6&page=0`);
	const newses = res.data.data;

	return {
		props: {
			newses,
		},
	};
}
