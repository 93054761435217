import { useState, useEffect, useRef } from "react";
import styles from "./announcement.module.css";

const Announcement = () => {
	// const [isOpen, setIsOpen] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const modalRef = useRef(null);

	const closeModal = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "Escape") {
				closeModal();
			}
		};

		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				closeModal();
			}
		};

		document.addEventListener("keydown", handleKeyDown);
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	if (!isOpen) {
		return null;
	}

	return (
		<div className={styles.modalOverlay}>
			<div className={styles.modalContent} ref={modalRef}>
				<div className={styles.modalHeader}>
					<h4 className={styles.modalTxt}></h4>
					<button className={styles.closeButton} onClick={closeModal}>
						&times;
					</button>
				</div>
				<div className={styles.imageContainer}>
					<img src="/img/announcement/notification_24_07_22.png" alt="Announcement" className={styles.announcementImage} height={100} />
				</div>
			</div>
		</div>
	);
};

export default Announcement;
