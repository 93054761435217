import { format, parseISO } from "date-fns";
import { t } from "i18next";
import Link from "next/link";

const News = ({ newses }) => {

    return (
        <div className="section padding-top-bottom-100 over-hide background-white">
            <div className="container z-bigger-10 position-relative">
                <div className="row pb-5">
                    <div className="col-md align-self-center text-center text-md-left">
                        <h3 className="mb-3 mb-md-0 color-blue">{t('home.newsSubject')}</h3>
                    </div>
                    <div className="col-md-auto align-self-center text-center">
                        <Link href="/news" className="btn btn-blue btn-radius parallax-elements js-tilt mt-2">{t('home.newsLabel')}<i className="uil uil-plus size-24 ml-2"></i></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="section">
                            <div className="section swiper-news">
                                <div className="swiper-wrapper pb-5">
                                    {newses.content.map((news) => (
                                    <div key={news.id} className="swiper-slide">
                                        <div className="section news-wrap">
                                            <Link className="" href={news.webViewUrl}>
                                                <div className="img-news" style={{ backgroundImage: `url(${news.imageUrl}), url('/img/edaero_none.png')` }}></div>
                                            </Link>
                                            <div className="section p-3">
                                                <div className="row mb-2">
                                                    <div className="col">
                                                        <p className="mb-0 size-13">{format(parseISO(news.takeAt), 'Y.MM.dd.')}</p>
                                                    </div>
                                                    <div className="col-auto">
                                                        <p className="mb-0 size-13 d-none">7 min. read</p>
                                                    </div>
                                                </div>
                                                <Link href={news.webViewUrl} className="link-normal "><h6 className="mb-0">{news.title}</h6></Link>
                                                <p className="mb-3 mt-2">{news.subTitle}</p>
                                                <Link href={news.webViewUrl} className="link-dark link-light ">{t('home.newsArticleLabel')}<i className="uil uil-arrow-right size-20 ml-1"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default News;
