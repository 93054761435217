import Image from "next/image";
import logoPlane from "/public/img/logo-plane.svg";
import Link from "next/link";

export default function Company() {
    return (
        <div className="section padding-top-bottom-100 over-hide background-blue-3">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-8 col-md-5 pr-lg-4 pr-xl-5 img-wrap align-self-center"  data-scroll-reveal="enter bottom move 50px over 0.4s after 0.3s">
                        <Image src={logoPlane} className="border-4" alt="" />
                    </div>
                    <div className="col-sm-8 col-md-7 pl-lg-4 pl-xl-5 align-self-center pt-4 pt-md-0"  data-scroll-reveal="enter bottom move 50px over 0.4s after 0.3s">
                        <h3 className="mb-3 color-white">이대로</h3>
                        <p className="mb-4 color-white">이대로는 입시정보 불균형을 해소하고<br />
                            모든 학생이 동등한 기회를 갖는 세상을 만들어 갑니다.</p>
                        <Link href="/companyinformation" className="btn btn-white-black btn-radius parallax-elements js-tilt">자세히 보기<i className="uil uil-arrow-right size-20 ml-2"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
